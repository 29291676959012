<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('insurance_agents_class.classes') }}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/insuranceagents/insurance-agents" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{ $t('back') }}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <div class="card-body p-0">

                    <div class="form-group row">

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents_class.name')}}<span class="text-danger">*</span></label>
                            <input v-model="data.name" type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.name[0] }}
                        </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('status')}}</label>
                            <div class="input-group">
                                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch></b-form-checkbox>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents_class.notes')}}</label>
                            <textarea type="text" v-model="data.note" class="form-control" :class="  validation && validation.note ? 'is-invalid' : ''"></textarea>
                            <span v-if="validation && validation.note" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.note[0] }}
                        </span>
                        </div>
                    </div>

                    <div class="form-group row">

                            <div class="col-md-12">
                                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                                    <h6 class="mt-2">{{$t('insurance_agents_class.class_details')}}</h6>
                                    <button type="button" class="btn btn-primary" @click="addItemRowToList">{{$t('add_more')}}</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                    <tr>
                                      <th width="20%">
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $t('insurance_agents_class.categories') }}</span>
                                          <a href="/settings/categories" target="_blank" class="btn btn-primary btn-sm p-1">
                                            <i class="fa fa-plus add-new"></i>
                                          </a>
                                        </div>
                                      </th>
                                        <th width="20%">{{ $t('insurance_agents_class.discount')}}</th>
                                        <th width="20%">{{ $t('insurance_agents_class.company_copayment')}}</th>
                                        <th width="20%">{{ $t('insurance_agents_class.client_copayment')}}</th>
                                        <th width="20%">{{ $t('insurance_agents_class.max_copayment_type')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(row, index) in class_details" :key="index">
                                        <td>
                                            <!-- <input type="text" v-model="row.category_id" class="form-control" :placeholder="$t('insurance_agents_class.categories')"/> -->
                                            <treeselect
                                                    :class="validation && validation.category_id ? 'is-invalid' : ''"
                                                    :options="categories"
                                                    :load-options="loadOptions"
                                                    :multiple="false"
                                                    :value="row.category_id"
                                                    @input="updateValue(index, $event)"
                                                    :searchable="true"
                                                    :show-count="true"
                                                    :no-children-text="$t('No_sub_options')"
                                                    :no-options-text="$t('No_options_available')"
                                                    :no-results-text="$t('No_results_found')"
                                                    :placeholder="$t('Select')">
                                            </treeselect>
                                        </td>
                                        <td class="text-center">
                                            <input type="number" min="0" v-model="row.discount" class="form-control" :placeholder="$t('insurance_agents_class.discount')"/>
                                        </td>
                                        <td class="text-center">
                                            <input type="number" min="0" v-model="row.company_copayment" class="form-control" :placeholder="$t('insurance_agents_class.company_copayment')"/>
                                        </td>
                                        <td>
                                            <input type="number" min="0" v-model="row.client_copayment" class="form-control" :placeholder="$t('insurance_agents_class.client_copayment')"/>
                                        </td>
                                        <td>
                                            <!-- <input type="text" v-model="row.max_copayment_type" class="form-control" :placeholder="$t('insurance_agents_class.max_copayment_type')"/> -->
                                            <select name="" id="f_status" v-model="row.max_copayment_type" type="text" class="custom-select">
                                                <option v-for="(row, index) in copayment_type" :value="row.id" :key="index">{{ row.title }}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <v-icon class="text-danger" color="danger" small v-if="class_details.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    <div class="col-lg-12 mb-8 text-center row justify-content-center">
                        <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
                    </div>
                    <div class="col-md-12">
                        <hr>
                    </div>
                    <div class="col-lg-12 mb-5 mt-15">
                        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tableNotes">
                            <template slot="status" slot-scope="props">
                                <b-form-checkbox size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                            </template>
                            <template slot="actions" slot-scope="props">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                                <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                            </template>
                        </v-server-table>
                    </div>
                </div>
            </div>

        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "Notes",
        // props:['insurrenceId'],
        data() {
            return {
                mainRoute: 'insuranceagents/insurance-agents-class',
                subMainRoute: 'insuranceagents/insurance-agent-class',
                mainRouteDependency: 'base/dependency',

                data: {
                    insurrence_id: null,
                    name: null,
                    note: null,
                    status: true,
                },
                validation: null,
                columns: ['name', 'notes', 'status', 'actions'],
                idEditing: null,
                insurrenceId: this.$route.params.id,
                categories: [],
                class_details: [],
                copayment_type: [
                    {id: 1, title: this.$t('client')},
                    {id: 0, title: this.$t('insurance_agent')},
                ],
                class_details_form: {
                    id: null, category_id: null, discount: null,
                    company_copayment: null, client_copayment: null, max_copayment_type: null,
                },
            };
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('insurance_agents_class.name'),
                        notes: that.$t('insurance_agents_class.notes'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },

                    filterByColumn: false,
                    customFilters: [],
                    filterable: false,
                    orderBy: {'column': 'id'},
                    sortable: [],
                    alwaysShowPerPageSelect: false,
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            // filter: data.query,
                            insurrence_id: that.insurrenceId,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        methods: {
            getFetch() {
                this.$refs.tableNotes.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    class_details: this.class_details,
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
                    class_details: this.class_details,
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            editItem(item) {
                this.idEditing = item.id;
                ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
                    this.isEditing = true;
                    this.data.insurrence_id = response.data.data.insurrence_id;
                    this.data.name = response.data.data.name;
                    this.data.note = response.data.data.notes;
                    this.data.status = response.data.data.status;
                    this.class_details = response.data.data.class_details;

                    if (response.data.data.class_details && response.data.data.class_details.length <= 0) {
                        this.addItemRowToList();
                    }
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

            addItemRowToList() {
                this.class_details.unshift(this.class_details_form);
                this.class_details_form = {
                    id: null, category_id: null, discount: null,
                    company_copayment: null, client_copayment: null, max_copayment_type: null,
                }
            },
            removeItemRowFromList(index) {
                this.class_details.splice(index, 1);
            },

            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            afterSave() {
                this.data = {
                    insurrence_id: this.insurrenceId,
                    name: null,
                    note: null,
                    status: null,
                };
                this.class_details = [];
                this.addItemRowToList();
                this.idEditing = null;
                this.isEditing = false;
            },
            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    status: (status ? 1 : 0),
                }).then(response => {
                    this.getFetch();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
                    this.categories = response.data.data;
                });
            },
            loadOptions() {
            },
            updateValue(index, value) {
                this.class_details[index].category_id = value
                // this.class_details_form.category_id = value
            },
        },
        mounted() {
            this.getCategories();
            if (this.class_details.length <= 0) {
                this.addItemRowToList();
            }
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.insurance_agents"), route:'/insuranceagents/insurance-agents'}, {title: this.$t('insurance_agents_class.classes')}]);
            this.data.insurrence_id = this.insurrenceId;
        },
    }
</script>